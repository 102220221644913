;(function($){

    function Slides() {
   	 	var _ =  this;

   	 	_.contentSlider();
      _.contentAboutsSlider();
      _.recipesSlide();
      _.recipesSlides2();
      _.productsSlide();
      _.recipeSlide();
      _.productSlider();
      _.productSlide();
      _.listProductsSlide();

      $(document).on("click", ".nav-tab > li > a",  function(e){
          e.preventDefault();

          $(".nav-tab li a").removeClass('active');              
          $(".tabs .tab").addClass('hidden');

          $(this).addClass('active');
          $(document).find($(this).attr('href')).removeClass('hidden');

         $('.slide-products').slick('refresh');
          //$('.slide-products').slick('reinit');
      });
    }

    Slides.prototype.contentSlider =  function() {

       var _ = this;

       _.elementPresentationSlider = $('.content-slider');

      

       _.elementPresentationSlider.slick({
	        fade: true,
	        arrows: true,
	        dots: false,
	        autoplay: true,
	        autoplaySpeed: 4000,
	        pauseOnFocus:false,
	        prevArrow:'<div class="slick-prev"></div>',
          	nextArrow:'<div class="slick-next"></div>',
          	responsive: [
            {
              breakpoint: 990,
                settings: {
                  arrows:false,
                }
            },
            {
              breakpoint: 480,
                settings: {
                  arrows:false,
                }
            }
          ]
  	   });
    };

    Slides.prototype.contentAboutsSlider =  function() {

       var _ = this;

       _.elementPresentationSlider = $('.slider-abouts');

      

       _.elementPresentationSlider.slick({
          arrows: true,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          prevArrow:'<div class="slick-prev"></div>',
          nextArrow:'<div class="slick-next"></div>',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
              }
            }
          ]
       });
    };

    Slides.prototype.recipesSlide = function() {

      var _ = this;

       _.elementGallerySliderCoatings = $('.recipes-slides');

      _.elementGallerySliderCoatings.slick({
          rows: 2,
          slidesToShow: 3,
          dots: true,
          arrows: false,
          infinite: true,
          speed: 300,
          customPaging : function(slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return '<a><div class="dots"></div></a>';
          },
          responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidestoShow: 3,
                    rows: 3,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    rows: 1,
                    slidestoShow: 1,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    dots: true
                }
            }
          ]
      });
    }

    Slides.prototype.recipeSlide =  function() {

       var _ = this;

       _.elementPresentationSlider = $('.recipe-slides');

      

       _.elementPresentationSlider.slick({
          arrows: false,
          dots: true,
          autoplay: true,
          autoplaySpeed: 4000,
          pauseOnFocus:false,
          customPaging : function(slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return '<a><div class="dots"></div></a>';
          },
            responsive: [
            {
              breakpoint: 990,
                settings: {
                  arrows:false,
                }
            },
            {
              breakpoint: 480,
                settings: {
                  arrows:false,
                }
            }
          ]
       });
    };

    Slides.prototype.productsSlide = function() {

      var _ = this;

       _.elementGallerySliderCoatings = $('.slide-products');

      _.elementGallerySliderCoatings.slick({
          rows: 3,
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 4,
          dots: true,
          arrows: false,
          infinite: false,
          speed: 300,
          customPaging : function(slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return '<a><div class="dots"></div></a>';
          },
          responsive: [
            {
                breakpoint: 768,
                settings: {
                    rows: 2,
                    slidesToShow: 2,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    rows: 1,
                    slidesToShow: 1,
                    dots: true
                }
            }
          ]
      });
    } 

    Slides.prototype.recipesSlides2 = function() {

      var _ = this;

       _.elementGallerySliderCoatings = $('.recipes-slides2');

      _.elementGallerySliderCoatings.slick({
          rows: 1,
          slidesToShow: 4,
           slidesToScroll: 4,
          dots: true,
          arrows: false,
          infinite: true,
          speed: 300,
          customPaging : function(slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return '<a><div class="dots"></div></a>';
          },
          responsive: [
            {
                breakpoint: 768,
                settings: {
                    rows: 2,
                    slidestoShow: 3,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    rows: 2,
                    slidestoShow: 2,
                    dots: true
                }
            }
          ]
      });
    }

    Slides.prototype.productSlider =  function() {

       var _ = this;

       _.elementPresentationSlider = $('.slider-products');

      

       _.elementPresentationSlider.slick({
          arrows: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 4000,
          pauseOnFocus:false,
          prevArrow:'<div class="slick-prev"></div>',
            nextArrow:'<div class="slick-next"></div>',
            responsive: [
            {
              breakpoint: 990,
                settings: {
                  arrows:false,
                }
            },
            {
              breakpoint: 480,
                settings: {
                  arrows:false,
                }
            }
          ]
       });
    };

    Slides.prototype.productSlide =  function() {

       var _ = this;

       _.elementPresentationSlider = $('.product-slides');

      

       _.elementPresentationSlider.slick({
          arrows: false,
          dots: true,
          autoplay: true,
          autoplaySpeed: 4000,
          pauseOnFocus:false,
          customPaging : function(slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return '<a><div class="dots"></div></a>';
          },
            responsive: [
            {
              breakpoint: 990,
                settings: {
                  arrows:false,
                }
            },
            {
              breakpoint: 480,
                settings: {
                  arrows:false,
                }
            }
          ]
       });
    };

      Slides.prototype.listProductsSlide = function() {

      var _ = this;

       _.elementGallerySliderCoatings = $('.slide-list-products');

      _.elementGallerySliderCoatings.slick({
          rows: 1,
          slidesToShow: 4,
          dots: true,
          arrows: false,
          infinite: true,
          speed: 300,
          customPaging : function(slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return '<a><div class="dots"></div></a>';
          },
          responsive: [
            {
                breakpoint: 768,
                settings: {
                    rows: 1,
                    slidestoShow: 3,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    rows: 1,
                    slidestoShow: 2,
                    dots: true
                }
            }
          ]
      });
    }

   	new Slides();

}(jQuery));
 
 
 
 
